import 'jquery';
import { default as $ } from 'jquery';

$(window).on("load",function() {
  $(window).scroll(function() {
  var pageTop = $(document).scrollTop();
    var windowBottom = pageTop + $(window).height();
    $(".addAnimation").each(function() {
      /* Check the location of each desired element */
      var objectBottom = $(this).offset().top;
      
      /* If the element is completely within bounds of the window, fade it in */
      if (objectBottom < windowBottom) { //object comes into view (scrolling down)
        jQuery(this).addClass('m-fadeIn');
      } else { //object goes out of view (scrolling up)
        jQuery(this).removeClass('m-fadeIn');
      }
    });
  }).scroll(); //invoke scroll-handler on page-load

  $(window).scroll(function() {
  var pageTop = $(document).scrollTop();
    var windowBottom = pageTop + $(window).height();
    $(".addImageAnimation").each(function() {
      /* Check the location of each desired element */
      var objectBottom = $(this).offset().top;
      
      /* If the element is completely within bounds of the window, fade it in */
      if (objectBottom < windowBottom) { //object comes into view (scrolling down)
        jQuery(this).addClass('img-animation');
      } 
      else { //object goes out of view (scrolling up)
        jQuery(this).removeClass('img-animation');
      }
    });
  }).scroll(); //invoke scroll-handler on page-load

  $(window).scroll(function() {
  var pageTop = $(document).scrollTop();
    var windowBottom = pageTop + $(window).height();
    $(".addAnimation-button").each(function() {
      /* Check the location of each desired element */
      var objectBottom = $(this).offset().top;
      
      /* If the element is completely within bounds of the window, fade it in */
      if (objectBottom < windowBottom) { //object comes into view (scrolling down)
        jQuery(this).addClass('m-fadeIn-button');
      }
    });
  }).scroll(); //invoke scroll-handler on page-load

  $(window).scroll(function() {
    var pageTop = $(document).scrollTop();
    var windowBottom = pageTop + $(window).height();
      $(".addAnimation1").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fadeIn');
        };
      });

      $(".addAnimation2").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fade2');
        };
      });

      $(".addAnimation3").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fade3');
        };
      });

      $(".addAnimation4").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fade4');
        };
      });

      $(".addAnimation5").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fade5');
        };
      });
    }).scroll(); //invoke scroll-handler on page-load
});
