import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-theme-options.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: 20,
    slidesToShow: 6,
    arrows: false,
    draggable: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    rows: 0,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 3.5,
            }
        },
        {
            breakpoint: 670,

            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 450,

            settings: {
                slidesToShow: 1.5,
            }
        }
    ]
});


$('.m-theme-extras.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: 20,
    slidesToShow: 6,
    arrows: false,
    draggable: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    rows: 0,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 3.5,
            }
        },
        {
            breakpoint: 670,

            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 450,

            settings: {
                slidesToShow: 1.5,
            }
        }
    ]
});