Splitting();

ScrollOut({
    threshold: .3,
    once: true,
});

$(".m-gallery-media").hide();

$(document).ready(function() {

    $(".m-gallery-media").each(function(i) {
          $(this).delay(300*i).fadeIn();
     });
 });