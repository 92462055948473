import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-info-carousel.-isCarousel').slick({
    dots: false,
    infinite: true,
    margin: 20,
    slidesToShow: 1,
    arrows: true,
    prevArrow: '<button type="button" aria-label="Previous" class="slick-prev"><i class="fa-solid fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" aria-label="Next" class="slick-next"><i class="fa-solid fa-chevron-right"></i></button>',
    draggable: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    controls: true,
    autoplay: true,
    autoplaySpeed: 4000,
    rows: 0,
    responsive: [
        {
            breakpoint: 768,

            settings: {
                arrows: false,
            }
        },
    ]
});