import { default as $ } from 'jquery';

$(".m-gallery-button").on("click", function(){
    var id = $(this).attr('id');
    $('.m-gallery-media').each(function() {
        var attr = $(this).attr('data-img');
        if(attr != id){
            $(this).hide();
        } else {
            $(this).show();
        };
    });
});