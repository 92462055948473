import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function () {
    if (jQuery(window).width() < 768) {
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 50) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
        
    } else {
        
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 100) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
    }
});

$('#mobileDropdownButton').on('click', function(){
    $('#mobileDropdown').slideToggle();
});