// Vendor js libraries
import '../../../node_modules/jquery/dist/jquery.min.js';
import 'bootstrap';

// How to import specific libraries into js file

    // jQuery js library
    // import 'jquery';
    // import { default as $ } from 'jquery';

    // Character splitting js library
    // import Splitting from "splitting";

    // ScrollOut animation js library
    // import ScrollOut from "scroll-out";

    // Mmenu js library
    // import 'mmenu-js';



// Parachute js files
import './parachute/parachute';